.videoFilterCardContainer {
    background: var(--background-light);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.videoFilterCardContainer > p {
    margin-top: 15px;
    color: var(--grey-dark);
    font-size: 0.8rem;
}

.videoFilterCardHeader, 
.videoFilterCardTargetImageSelector, 
.videoFilterCardSetup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.videoFilterCardTargetImageSelector > aside > span {
    color: var(--grey-dark);
    font-size: .7rem;
    padding-top: 3px;
    padding-left: 6px;
}


.videoFilterCardHeader > h3 {
    margin-bottom: 0;
    font-size: 1.2rem;
}


.videoFilterCardHeader > h3 > span {
    margin-bottom: 0;
    font-size: .9rem;
    color: var(--grey-dark);
}



.videoFilterCardHeader > button {
    height: 30px;
    width:30px;
    border-radius: 5px;
}

.videoFilterCardSetupItemsContainer {
    display: flex;
    align-items: center;
}

.mainPreviewButton > p {
    padding: 0;
    font-weight: bold;
    font-size: .8rem;
}

.cardPreviewButton {
    padding: 0 !important;
    height: 30px !important;
    font-weight: bold !important;
    cursor: pointer;
    transition: 0.2s;
}
.cardPreviewButton:hover {
    background-color: var(--primary-color-hover);
}

.videoFilterCardSetupItem,
.videoFilterCardDegreeItem {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: var(--background);
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    cursor: pointer;
    transition: 0.2s;   
}

.videoFilterCardSetupItem.active,
.videoFilterCardDegreeItem.active {
    background: var(--primary-color);
}

.videoFilterCardSetupItem span {
    width: 15px;
    height: 15px;
    position: absolute;
    right: -3.5px;
    bottom: -3.5px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);

}

.videoFilterCardDegreeItem {
    width: 70px;
    position: relative;
}

.videoFilterCardDegreeItem > p {
    padding-right: 7px;
}

.videoFilterCardDegreeItem span {
    position: absolute;
    top: 3px;
    right: 16px;
    font-size: .8rem;
}

.videoFilterCardTargetImageSelector {
    border: 1px solid var(--grey-dark);
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
}

.videoFilterCardTargetImageSelector:hover {
    background-color: #161618;
}

.videoFilterCardTargetImageSelector > aside {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.videoFilterCardTargetImageSelector > aside > img {
    width: 60px;
    height: 60px;
    margin: 0;
    border-radius: 5px;
    background: var(--background);
    border: none;
    object-fit: cover;
}

.videoFilterCardTargetImageSelector h4 {
    margin: 0;
    margin-left: 20px;
    font-size: 1rem;
}

.videoFilterCardTargetImageSelector svg{
    margin-right: 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.videoFilterCardSetup button {
    height: 35px;
    padding: 0 20px;
    border-radius: 5px;
    font-weight: normal;
}

.imageTargetsOptions {
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    background: var(--background-light);
    border-radius: 5px;
    z-index: 1000;
    -webkit-box-shadow: 0px 14px 22px 2px rgba(0,0,0,0.69); 
    box-shadow: 0px 14px 22px 2px rgba(0,0,0,0.69);

}

.imageTargetItem {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    background: var(--background-light);
    transition: 0.2s;
}

.imageTargetItem:hover {
    background-color: #161618;
}

.imageTargetItem > img {
    margin: 0;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.imageTargetItem > p {
    margin-left: 20px;
}

@media (max-width: 420px) {
    .mainPreviewButton {
        padding: 0;
    }
    
    .mainPreviewButton > p {
        padding: 0;
        font-weight: bold;
        font-size: .6rem;
    }
    .videoFilterCardSetup button {
        padding: 10px;
    }
}