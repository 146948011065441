.uploadImageContainer {
    width: 100%;
    height: 100%;
    display: flex;

}

.imageTargetContainer {
    text-align: center;
}

.imageTargetContainer > p {
    margin-bottom: 20px;
}

.uploadImageTargetContainer {
    background: var(--background);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    transition: 0.2s;
}

.uploadImageTargetContainer:hover {
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.uploadImageTargetContainer > img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.uploadImageTargetContainer > button {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.uploadImageTargetContainer > button > svg > path {
    transition: 0.2s;

}

.uploadImageTargetContainer > button:hover > svg > path {
    fill:var(--primary-color-hover);
}

.imageTargetDetails {
    text-align: center;
    width: 100%;
}

.imageTargetDetails > p {
    margin-bottom: 20px;
}


.imageTargetNameContainer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;
    height: 120px;
    margin-left: 30px;
}

.imageTargetNameContainer > button {
    border-radius: 5px;   
}

.errorMessage {
    font-size: .85rem;
    color: red;
    padding-bottom: 5px;
}


@media (max-width: 530px) {
    .uploadImageTargetContainer {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .uploadImageContainer {
        display: block;
    }

    .imageTargetNameContainer {
        margin-left: 0;
    }
}
