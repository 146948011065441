@import url(https://fonts.googleapis.com/css2?family=Lalezar&family=Montserrat:wght@200;400;600&family=Orbitron:wght@400;600;900&family=Ubuntu:wght@300;400;700&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0) !important;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(46, 46, 46); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
    /* background-image: url("assets/perkLab_purple.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-hover); 
    /* background-image: url("assets/perkLab_purple.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
}


@media screen and (max-width: 1044px) {
    html {
      padding: 0 40px;
    }

    .App {
      padding: 40px;
    }
}


@media screen and (max-width: 480px) {
    html {
      padding: 0 15px;
    }

    .App {
      padding: 15px;
    }
    .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
      top: 120px !important;
      width: 80vw !important;
      right: 10px !important;
      left: auto !important;
    }
}
:root {
  --background: #090C08;
  --background-light: #202024;
  --primary-color: #41a557;
  --primary-color-hover: #582A74;
  --grey-light: #E1E1E6;
  --grey-dark: #A5A5B0;
  --facebook: #4C77BC;
  --green: #4F9D69;
  --yellow: #FFE74C;
  --white: #F8F8F8;
  --google: #F0386B;
  --email: #F5A6E6;
}

a {
  text-decoration: none !important;
}

html {
  /* background: var(--background); */
  margin: 0 auto;
  max-width: 1044px;
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  /* background: #090C08; */
  background: url(/static/media/perkLab_purple.17505a8f.jpg) no-repeat center center fixed;

  background-size: cover;
  
}


.App {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: var(--background); */
  
  color: #F8F8F8;
  
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  /* min-height: calc(100vh - 120px); */
  /* min-height: calc(100vh - 80px); */
  position: relative;
}

.firebaseui-title {
      font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0;
    font-family: 'Lalezar', cursive;
}

.firebaseui-label {
  padding-right: 10px;
  width: 200px;
}
.firebaseui-id-submit {
  background-color: #41a557 !important;
  background-color: var(--primary-color) !important;
  margin-top: 20px;
}

.hidden {
  display:none;
}

.firebaseui-textfield {
  padding: 3px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-bottom: 100px;
  margin-top: 200px;
}

button {
  border: none;
  border-color: transparent;
  border-radius: 5px;
  background-color: #41a557;
  background-color: var(--primary-color);
  cursor: pointer;
  color: #F8F8F8;
  color: var(--white);
  font-family: 'Ubuntu', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  transition: 0.2s;
}

button:hover{
  background-color: #582A74;
  background-color: var(--primary-color-hover);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input {
  background-color: #090C08;
  background-color: var(--background);
  border: none;
  height: 45px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
  color: #E1E1E6;
  color: var(--grey-light);
  font-family: 'Ubuntu', sans-serif;
}

.mainSpinner {
  width: 100% !important;
  height: 93vh !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerCircle {
  width: 100px !important;
  height: 100px !important;
  color: #41a557;
  color: var(--primary-color);
}

.Toastify__toast-container {
  margin-top: 100px;
}

#calendar {
  position: absolute;
  right: 0px;
  bottom: 23%;
  z-index: 99;
}

#step {
  margin-bottom: 40px;
  color: #A5A5B0;
  color: var(--grey-dark);
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.rdrCalendarWrapper {
  background-color: #090C08 !important;
  background-color: var(--background) !important;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 1px #090C08;
  box-shadow: 0px 0px 12px 1px var(--background);
  padding: 20px;
  right: 0;
}

.rdrCalendarWrapper,
.rdrDateDisplayWrapper {
  background-color: #202024 !important;
  background-color: var(--background-light) !important;
  border-radius: 5px;

}

.rdrDayNumber span {
  color: white !important;
}

.rdrNextPrevButton,
.rdrNextButton {
  background-color: #090C08 !important;
  background-color: var(--background) !important;

}

.rdrPprevButton i {
  border-color: transparent #41a557 transparent transparent !important;
  border-color: transparent var(--primary-color) transparent transparent !important;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #41a557 !important;
  border-color: transparent transparent transparent var(--primary-color) !important;
}

.rdrDateInput {
  background-color: #090C08 !important;
  background-color: var(--background) !important;
}


.ReactModal__Overlay {
  z-index: 20000 !important;
  background-color: rgba(0, 0, 0, 0.8)!important;
}

.ReactModal__Content {
  border: none !important;
  background-color: #202024 !important;
  background-color: var(--background-light) !important;
  color: #F8F8F8;
  color: var(--white);
  max-width: 500px;
  padding: 40px !important;
  text-align: center;
  position: relative;
  
}

.ReactModal__Content > header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content > header > h2 {
  font-family: 'Lalezar', sans-serif;
  margin-top: 13px;
  margin-left: 20px;
  margin-right: 20px;
}

.ReactModal__Content > p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #A5A5B0;
  color: var(--grey-dark);
  line-height: 30px;
  
}

.ReactModal__Content > footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.ReactModal__Content > footer > button {
  width: 140px;
  border-radius: 5px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.input-group-app{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.App-header {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 150px;
  
}

#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  max-width: 250px;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.horizontal-center {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 5%;
}

.ReactCrop__image {
  max-height: 330px;
}

/* img {
    max-width: 60%;
    max-height: 100%;
} */

.portrait {
    height: 320px;
    width: auto;
    padding: 10px;
}

.landscape {
    height: 320px;
    width: auto;
    padding: 10px;
}

.square {
    height: 290px;
    width: auto;
    padding: 10px;
}

.video-reponsive-landscape {
  width: 100%;
  max-height: 100%;
}

.video-reponsive-portrait {
  max-width: 100%;
  height: 100%;
}

.experience-controls-md {
  width: 200px;
  float: left;
  margin-left: 10px;
  margin-top: 5px; 
}

.experience-controls-sm {
  width: 95px;
  float: left;
  margin-left: 10px;
  margin-top: 5px; 
}

@media (max-width: 420px){
  #calendar {
    transform: scale(.8);
    right: -40px !important;
    bottom: 10% !important;
  }
}
.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.errorContainer > aside {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorContainer > main {
    width: 50%;
}

.errorContainer > main > h1 {
    font-family: 'Lalezar', cursive;
    color: var(--primary-color);

}

.errorContainer > main > p {
    font-family: 'Ubuntu', sans-serif;
    color: var(--grey-dark);
    font-size: 1.3rem;
    margin-top: 50px;
}

.errorGoBack {
    border-radius: 20px;
    padding: 0 40px;
    margin: 0 auto;
    margin-top: 50px;
}

.logginPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.loginButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logginPageContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}


.logginPageContainer > h1 {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.logginPageContainer > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.logginPageContainer > main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-light);
    padding: 40px;
    width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.logginPageContainer > main > input {
    margin-bottom: 20px;
    width: 100%;
}

.loginAndRegister,
.googleAndFacebook {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.loginAndRegister > button,
.googleAndFacebook > button,
.logginPageContainer > main > button {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    font-size: .9rem;
}

.registerButton {
    background: transparent;
    border: 1px solid var(--primary-color);
}

.registerButton:hover {
    border: 1px solid var(--primary-color-hover);
}

.loginWith,
.orGuest {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.loginWith > span,
.orGuest > span {
    width: 30%;
    height: 1px;
    background: var(--grey-dark);
}

.orGuest > span {
    width: 45%;
}

.loginWith > p, 
.orGuest > p {
    margin: 0 20px;
    color: var(--grey-dark);
}


.logginPageContainer > main > button {
    width: 100%;
}

.logInWithGoogle, 
.logInWithFacebook {
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 460px) {
    .logginPageContainer {
        margin-top: 40px;
    }
    
    .logginPageContainer > h1 {
        font-size: 1.8rem;
    }

    .logginPageContainer > main {
        width: 100%;
        margin: 15px;
        padding: 30px;
    }

    .loginAndRegister > button,
    .googleAndFacebook > button,
    .logginPageContainer > main > button {
        width: 110px;
        font-size: .8rem;

    }
    .logginPageContainer > main > button {
        width: 100%;
    }

    .loginWith > p, 
    .orGuest > p {
        font-size: .8rem;
    }

    .loginWith > span {    
        width: 26%;
    }
}

.productContainer {
    background-color: var(--background-light);
    width: 240px;
    min-height: 320px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    position: relative;
    margin: 20px;
}

.productContainer > span {
    width: 100%;
    height: 3px;
    background: var(--primary-color);
}

.productContainer > ul {
    width: 100%;
    padding: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;
}

.productContainer > ul  > li {
    margin-bottom: 6px;
}

.productItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: .9rem;
}

.productItem > svg {
    margin-right: 10px;
}

.productContainer > button {
    font-family: 'Montserrat', sans-serif;
    width: calc(100% - 40px);
    margin: 20px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.priceTage {
    position: absolute;
    top: -20px;
    right: -25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.priceTagNumber {
    position: absolute;
    top: 35%;
    left: 53%;
    transform: translate(-50%, -50%)
}

.priceTagNumber.month  {
    left: 39%;
}

.priceTagNumber > span {
    position: absolute;
    font-size: 15px;
    bottom: 5px;
    right: -26px;
}
.subscriptionPageContainer {
    min-height: calc(100vh - 220px);
    width: 100%;
    margin: 30px 0;
    font-family: 'Lalezar', sans-serif;
    text-align: center;
}

.subscriptionPageContainer::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 600px;
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    z-index: 0;
    background: radial-gradient( 51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100% );
}

.subscriptionPageContainer > h1  {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
}

.subscriptionPageContainer > h2  {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
}

.subscriptionPageContainer > main {
    position: relative;
    z-index: 10;
    width: 100%;    
    min-height: calc(100vh - 480px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 40px; */
}

.subscriptionPageContainer > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.subscriptionPageContainer > p {
    font-family: 'Ubuntu', sans-serif;
    color: var(--grey-dark);
    text-align: center;
    margin: 0 150px;
    margin-bottom: 10px;
    
}

.subscriptionPageContainer > button {
    font-weight: bold;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    border-radius: 20px;
    padding: 0 30px;
    position: relative;
    z-index: 100;
    margin-bottom: 40px;
}

@media (max-width: 850px) {
    .subscriptionPageContainer > p {
        margin: 0;
        margin-bottom: 20px;
    }
}

.uploadImageContainer {
    width: 100%;
    height: 100%;
    display: flex;

}

.imageTargetContainer {
    text-align: center;
}

.imageTargetContainer > p {
    margin-bottom: 20px;
}

.uploadImageTargetContainer {
    background: var(--background);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    transition: 0.2s;
}

.uploadImageTargetContainer:hover {
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.uploadImageTargetContainer > img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.uploadImageTargetContainer > button {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.uploadImageTargetContainer > button > svg > path {
    transition: 0.2s;

}

.uploadImageTargetContainer > button:hover > svg > path {
    fill:var(--primary-color-hover);
}

.imageTargetDetails {
    text-align: center;
    width: 100%;
}

.imageTargetDetails > p {
    margin-bottom: 20px;
}


.imageTargetNameContainer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;
    height: 120px;
    margin-left: 30px;
}

.imageTargetNameContainer > button {
    border-radius: 5px;   
}

.errorMessage {
    font-size: .85rem;
    color: red;
    padding-bottom: 5px;
}


@media (max-width: 530px) {
    .uploadImageTargetContainer {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .uploadImageContainer {
        display: block;
    }

    .imageTargetNameContainer {
        margin-left: 0;
    }
}

.uploadButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    width: 100%;
    height: 100%;
    
    /* color: #FFFFFF;
    background: rgb(57, 154, 183);
    border: 1px solid rgb(69, 59, 155); */
    box-sizing: border-box;
    border-radius: 4px;
    /* height: 30px; */
    cursor: pointer;
    /* font-size: 16px; */
    /* padding-top: 6px; */
    text-align: center;
}

.nextButton{
    position: absolute;
    left: 45.87%;
    right: 5.91%;
    top: 67.86%;
    bottom: 27.46%;

    background: #86A8E7;
    border: 1px solid #86A8E7;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}

.videoFilterCardInput {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    padding-right: 10px;
}
.uploadFilterMainContainer {
    background: var(--background-light);
    border-radius: 5px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 30px;
}

.videoTooLong {
  color: red;
  margin-bottom: 20px;
}

.uploadFilterMainContainer > p {
    text-align: center;
}

.uploadedFilterContainer {
  height: 230px;
  /* margin: 0 30px; */
  margin-bottom: 15px;
}

.videoFormatContainer {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15px;
    align-content: stretch;
  }

  .uploadedVideoContainer {
    width: 100%;
    height: 100%;
    background: var(--background);
    border-radius: 5px;
    margin-top: 15px;
  }

  
.formatItem, .formatItem360 {
    justify-self: center;
    /* width: 102px; */
    height: 102px;
    width: 100%;
    min-height: 100px;
    background: var(--background);
    justify-self: stretch;
    align-self: stretch;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
  }

.formatItem:hover {
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.formatItem.active {
    background-color: var(--primary-color);
}

  
.formatItem > p, .formatItem360 > p {
    font-size: 0.7rem;
    margin-top: 10px;
    color: var(--grey-light);
    text-align: center;
}
.formatItem360 {
  background-color: var(--primary-color);
  justify-content: space-between;
  /* max-width: 115px; */
  min-width: 110px;
}

.formatItem360 > p {
  margin-bottom: 15px;
}

.formatItem360 > input {
  text-align: center;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  background: var(--background);
  width: 100%;
  border: none;
  color: var(--white);
  border-radius: 5px;
  margin: 0;
  height: 50px;
}

.formatItem360 input::-webkit-input-placeholder {
    color: var(--white);
}

.formatItem360 input:-ms-input-placeholder {
    color: var(--white);
}

.formatItem360 input::-ms-input-placeholder {
    color: var(--white);
}

.formatItem360 input::placeholder {
    color: var(--white);
}
  
.formatItem360 span {
    width: 5px;
    height: 5px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 33px;
}

.commingSoonContainer {
  position: absolute;
  width: 100%;
  height: 25px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
}

.commingSoonItem {
  color: var(--grey-dark) !important;
  
}



.commingSoonContainer > p {
  font-size: 10px;
  font-weight: 900;
}



.filterSetupContainer > p {
    text-align: center;

}
  
  
.filterSetupOptions {
    padding-top: 20px;
    /* margin-bottom: 20px; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15px;
    align-content: stretch;
}

.filterNameContainer {
  /* padding-bottom: 30px; */
  padding-top: 20px;
}

.filterNameContainer > p {
    text-align: center;
    margin-bottom: 20px;
}

.filterNameContainer > input {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.filterNameInputs {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterNameInputs input {
    width: 100%;
}

.filterNameInputs button {
    border-radius: 5px;
    padding: 0 30px;
    font-size: .9rem;
}

.disabled {
  background-color: rgb(65, 65, 65) !important;
  cursor: not-allowed;
}



.filterTransparentColor {
  width: 100%;
  margin-top: 20px;
}

.filterTransparentColor > p {
  text-align: center;
}

.filterTransparentInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.filterTransparentInputs > input {
  width: 47%;
}

.previewContainer {
  position: absolute;
  bottom: 0px;
  left: calc(-350px - 30px);
  width: 350px;
  height: 270px;
  background: var(--background-light);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px; 
box-shadow: 0px 0px 25px 1px #000000;
}

.previewContainerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.previewContainerHeader > h4 {
  font-size: 1rem;
}

.previewContainerHeader > div {
  position: absolute;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 0;
}

.videoContainer {
  width: 100%;
  height: 90%;
  background: var(--background);
  border-radius: 5px;
  margin-top: 15px;
}

.videoContainer > video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.videoContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.uploadedVideoContainer {
  width: 100%;
  height: 100%;
  background: var(--background);
  border-radius: 5px;
}

.uploadedVideoContainer > video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.uploadedVideoContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}
.targetImageCard {
    width: 100%;
    height: 100px;
    background: var(--background-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
}

.targetImageCardPictureAndName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.targetImageCardPictureAndName > img {
    height: 100%;
    width: 80px;
    border-radius: 5px;
    object-fit: cover;
}

.targetImageCardPictureAndName > p {
    margin-left: 20px;
}

.targetImageCardStatus {
    display: flex;
}

.delete-button {
    /* width: 35px; */
    height: 35px;
    border-radius: 5px;
    font-size: .8rem;
    margin-left: 20px;
}

.uploading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-dark);
}

.uploading > p, .uploading {
    font-size: .9rem;
}

.targetImageCardPictureContainer {
    width: 80px;
    height: 80px;
    background: var(--background);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.targetPicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    min-width: 80px;
}


.selectTargetImageSvgContainer {
    background: var(--background);
    width: 60px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectTargetImageSvg {
    position: relative !important;
    margin: 0 !important;
}
.videoFilterCardContainer {
    background: var(--background-light);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.videoFilterCardContainer > p {
    margin-top: 15px;
    color: var(--grey-dark);
    font-size: 0.8rem;
}

.videoFilterCardHeader, 
.videoFilterCardTargetImageSelector, 
.videoFilterCardSetup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.videoFilterCardTargetImageSelector > aside > span {
    color: var(--grey-dark);
    font-size: .7rem;
    padding-top: 3px;
    padding-left: 6px;
}


.videoFilterCardHeader > h3 {
    margin-bottom: 0;
    font-size: 1.2rem;
}


.videoFilterCardHeader > h3 > span {
    margin-bottom: 0;
    font-size: .9rem;
    color: var(--grey-dark);
}



.videoFilterCardHeader > button {
    height: 30px;
    width:30px;
    border-radius: 5px;
}

.videoFilterCardSetupItemsContainer {
    display: flex;
    align-items: center;
}

.mainPreviewButton > p {
    padding: 0;
    font-weight: bold;
    font-size: .8rem;
}

.cardPreviewButton {
    padding: 0 !important;
    height: 30px !important;
    font-weight: bold !important;
    cursor: pointer;
    transition: 0.2s;
}
.cardPreviewButton:hover {
    background-color: var(--primary-color-hover);
}

.videoFilterCardSetupItem,
.videoFilterCardDegreeItem {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: var(--background);
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    cursor: pointer;
    transition: 0.2s;   
}

.videoFilterCardSetupItem.active,
.videoFilterCardDegreeItem.active {
    background: var(--primary-color);
}

.videoFilterCardSetupItem span {
    width: 15px;
    height: 15px;
    position: absolute;
    right: -3.5px;
    bottom: -3.5px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);

}

.videoFilterCardDegreeItem {
    width: 70px;
    position: relative;
}

.videoFilterCardDegreeItem > p {
    padding-right: 7px;
}

.videoFilterCardDegreeItem span {
    position: absolute;
    top: 3px;
    right: 16px;
    font-size: .8rem;
}

.videoFilterCardTargetImageSelector {
    border: 1px solid var(--grey-dark);
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
}

.videoFilterCardTargetImageSelector:hover {
    background-color: #161618;
}

.videoFilterCardTargetImageSelector > aside {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.videoFilterCardTargetImageSelector > aside > img {
    width: 60px;
    height: 60px;
    margin: 0;
    border-radius: 5px;
    background: var(--background);
    border: none;
    object-fit: cover;
}

.videoFilterCardTargetImageSelector h4 {
    margin: 0;
    margin-left: 20px;
    font-size: 1rem;
}

.videoFilterCardTargetImageSelector svg{
    margin-right: 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.videoFilterCardSetup button {
    height: 35px;
    padding: 0 20px;
    border-radius: 5px;
    font-weight: normal;
}

.imageTargetsOptions {
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    background: var(--background-light);
    border-radius: 5px;
    z-index: 1000; 
    box-shadow: 0px 14px 22px 2px rgba(0,0,0,0.69);

}

.imageTargetItem {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    background: var(--background-light);
    transition: 0.2s;
}

.imageTargetItem:hover {
    background-color: #161618;
}

.imageTargetItem > img {
    margin: 0;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.imageTargetItem > p {
    margin-left: 20px;
}

@media (max-width: 420px) {
    .mainPreviewButton {
        padding: 0;
    }
    
    .mainPreviewButton > p {
        padding: 0;
        font-weight: bold;
        font-size: .6rem;
    }
    .videoFilterCardSetup button {
        padding: 10px;
    }
}

.experienceNameContainer {
    position: relative;
    height: 50px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.experienceNameContainer > p {
    color: var(--grey-dark);
    margin-top: 15px;
    font-size: .9rem;
}

@media (max-width: 420px) {
    .experienceNameContainer > p {
        font-size: .8rem;
        text-align: center;
    }
}
/* .logoUploadButton {
   
    min-height: 40px;
    color: #FFFFFF;
    background: rgb(57, 154, 183);
    border: 1px solid rgb(69, 59, 155);
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    padding-top: 6px;
    margin-top: 5px;
    width: 100%;
    text-align: center;
} */


.logoUploadButton {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: var(--white);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.logoUploadButton:hover {
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}
.logoAndNameContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logoAndNameContainer aside {
    text-align: left;
}

.logoAndNameContainer aside img {
    max-width: 90%;
}

.logoAndNameContainer main {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.logoAndNameContainer main h1 {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    margin-bottom: 30px;
}

.logoContainer {
    text-align: center;
    /* margin-right: 15px; */
}

.logoAndNameContainer .topContainer {
    background-color: var(--background-light);
    border-radius: 5px;
    width: 100%;
    /* width: 340px; */
    /* height: 210px; */
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.logoAndNameContainer h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 30px;
}

.logoAndNameInputsContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}

.logoAndNameInputsContainer .uploadLogo {
    min-width: 120px;
    height: 120px;
    background-color: var(--background);
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.uploadLogo img {
    border-radius: 5px;
    width: 120px;
    height: 120px;
    object-fit: contain;
}


.logoAndNameInputsContainer .rightInputs {
    /* height: 100%;
    width: calc(100% - 107px - 30px); */
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: column;
    text-align: center;
    /* margin-left: 15px;
    margin-right: 15px; */
}

.rightInputs > p,
.logoContainer > p {
    margin-bottom: 15px;
}

.logoAndNameInputsContainer .uploadLogo .uploadImageButton {
    background-color: transparent;
    /* position: relative; */
    /* border-radius: 5px;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: var(--white);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.swithLogo {
    background-color: var(--primary-color);
    border-radius: 5px;
    left: auto !important;
    top: auto !important;
    right: -5px;
    bottom: -5px;
}

.logoAndNameInputsContainer .uploadLogo .uploadImageButton svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    pointer-events: none;
}

.logoAndNameInputsContainer .rightInputs input {
    height: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--background);
    border: none;
    color: var(--grey-light);
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
}

.logoAndNameInputsContainer .rightInputs .colorPicker {
    /* background-color: var(--primary-color);
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    border: none;
    width: 100%;
    border-color: transparent;
    outline: none; */
    width: 100%;
    height: 100%;
    position: relative;
}

.colorPicker p {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 140px;
}

.rightInputs {
    position: relative;
}

#colorPickerTextInput {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
    height: 30px;
}

#colorPickerInput {
    height: 20px;
    width: 100%;
    border-color: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
}

.colorPickerContainer {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background);
    position: relative;
    cursor: pointer;
    transition: 0.2s;
}

.colorPickerContainer:hover {
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

label {
    margin-bottom: 0 !important;
}

.colorPickerContainer > p {
    font-size: 1rem;
    margin-top: 15px;
}

.logoAndNameContainer .bottomContainer {
    background-color: var(--background-light);
    margin-top: 15px;
    border-radius: 5px;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logoAndNameContainer > main > p {
    color: var(--grey-dark);
    text-align: center;
    margin-bottom: 30px;
}

.comboInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
}


.comboInputContainer input {
    border-radius: 5px;
    text-align: center;
    background-color: var(--background);
    border: none;
    color: var(--grey-light);
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    height: 40px;
    width: calc(100% - 110px - 30px);
}

.comboInputContainer button {
    background-color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    height: 100%;
    width: 110px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    color: var(--white)
}

.comboInputContainer button:hover {
    background-color: var(--primary-color-hover)
}

.createExpNextButton {
    margin-top: 25px;
    width: 130px;
    border-radius: 20px;
    
}

.datePickerContainer,
.rightInputs,
.logoContainer {
    margin: 0 5px;
    margin-bottom: 20px;
    
}

.colorButtonContainer {
    position: relative;
}

.premiumIcon {
    position: absolute;
    top: -6px;
    right: -3px;
}

.datePickerContainer > p {
    margin-bottom: 15px;
    text-align: center;
}

.datePickerButton {
    position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     background-color: var(--background);
     border-radius: 5px;
     width: 120px;
     height: 120px;
     cursor: pointer;
     transition: 0.2s;
}

.datePickerButton:hover {
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.textsContainer {
    font-size: 0.7rem;
    text-align: center;
    margin-top: 10px;
}

.textsContainer > p {
    color: var(--grey-dark);
}

.textsContainer > span {
    font-size: 1rem;
}

.personalizeFooter {
     display: flex;
     justify-content: center;
     align-items: center;
     padding-bottom: 40px;
     margin-top: 30px;
     cursor: pointer;
     transition: 0.2s;
}

.personalizeFooter:hover {
    transform: translateY(-5px);
}

.personalizeFooter > p {
    margin-left: 10px;
    color: var(--grey-dark);
}

.personalizeFooter > p > strong {
    color: var(--primary-color);
    font-weight: 900;
    font-size: 1.1rem;
}

.dateInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.durationInput {
    width: 40px;
    height: 30px;
    outline: none;
    font-size: 1.2rem;
    margin-top: 8px;
    text-align: center;
    position: relative;
    /* background-color: red; */
}

.dateInputContainer > span {
    /* position: absolute;
    right: 25px;
    bottom: 30px; */

}

.datePickerButton > span {
    color: var(--grey-dark);
}

@media (max-width: 940px) {
    .logoAndNameContainer main {
        padding: 0 10%;
    }
    .logoAndNameContainer aside img {
        display: none;
    }
}

@media (max-width: 640px) {
    .logoAndNameContainer main {
        padding: 0;
    }
}
@media (max-width: 480px) {
    .logoAndNameInputsContainer, .comboInputContainer {
        width: 100%;
    }
    .logoAndNameInputsContainer .rightInputs {
        /* width: calc(100% - 107px - 15px); */
        /* margin: 0 15px; */
    }
    .logoAndNameContainer h2 {
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .logoAndNameInputsContainer .uploadLogo {
        /* margin-left: 15px; */
    }

    /* .logoAndNameContainer .topContainer {
        height: 200px;
    } */

    .logoAndNameContainer .bottomContainer  {
        height: 140px;
    }

    .comboInputContainer input {
        width: calc(100% - 110px - 15px);
    }

    
    .logoAndNameContainer main h1 {
        font-size: 1.4rem;
    }

    .logoAndNameContainer > main > p {
        font-size: .8rem;
    }

    .personalizeFooter > p,
    .personalizeFooter > p > strong {
        font-size: .8rem;
    }
}
.mainTitle {
    text-align: center;
    margin-top: 120px;
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    font-size: 2rem;
}


.experienceNameContainer > p {
    color: var(--grey-dark);
    margin-top: 15px;
    font-size: .9rem;
}

.feedbackTargetImage {
    margin-bottom: 30px !important;
}

.experienceNameInputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.experienceNameInputContainer > svg {
    position: absolute;
    bottom: 20px;
    right: 75px;
    /* display: none; */
    opacity: 0;
    transition: 0.2s;
}

.inputExperienceName:focus ~ svg {
    opacity: 1;
    
}

.experienceNameInputContainer:hover > svg {
    opacity: 1;
}

.inputExperienceName {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-top: 10px;
    font-family: 'Lalezar', sans-serif;
    color: var(--white);
    font-size: 2rem;
    outline: none;
    border: 1px solid var(--primary-color);
    background: transparent;
    
}

.inputExperienceName::-webkit-input-placeholder {
    font-family: 'Lalezar', sans-serif;
    color: var(--grey-light);
    font-size: 2rem;
}

.inputExperienceName:-ms-input-placeholder {
    font-family: 'Lalezar', sans-serif;
    color: var(--grey-light);
    font-size: 2rem;
}

.inputExperienceName::-ms-input-placeholder {
    font-family: 'Lalezar', sans-serif;
    color: var(--grey-light);
    font-size: 2rem;
}

.inputExperienceName::placeholder {
    font-family: 'Lalezar', sans-serif;
    color: var(--grey-light);
    font-size: 2rem;
}

.createExperienceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: calc(100vh - 160px);
    margin-top: 80px;
    margin-bottom: 20px;
}

.logoAndColorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.createExperienceContainer.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 109px;
    min-height: calc(100vh - 100px);
}


.logoAndDatesContainer, .targetImageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;

}

.targetImageContainer {
    margin-top: 0;
}

.imageTargetText > h2 {
    color: var(--white);
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.imageTargetText > h3 {
    color: var(--grey-light);
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 24px;
}

.logoAndDatesContainer main {
    width: 100%;
    margin-bottom: 0;
}

.logoAndDatesContainer > img {
    width: 50%;
    padding-right: 50px;
}

.targetImageInfo {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.targetImageInfo > img {
    width: 60%;
    margin: 0 auto;
}

.targetImageInfo > aside {
    margin-top: 30px;
}

.targetImageDataContainer {
    width: 50%;
    height: 100%;
    text-align: center;
}

.targetImageDataContainer > h4 {
    font-size: 1rem;
    margin-bottom: 40px;
    color: var(--grey-light);
    line-height: 30px;
    font-family: 'Lalezar', cursive;
    font-size: 1.3rem;
}

.targetImageDataContainer > p {
    margin-bottom: 60px;
}

.targetImageDataContainer > h4 >strong {
    color: var(--primary-color);
}

.targetImageDataContainer > h1, .filtersDataContainer > h1 {
    text-align: center;
    font-size: 2rem;
    color: var(--grey-light);
    font-family: 'Lalezar', cursive;
    font-weight: normal;
}

.targetImageDataContainer > h2, .filtersDataContainer > h2 {
    text-align: center;
    font-size: 1rem;
    margin: 20px 0;
    color: var(--grey-dark);
}

.targetImageFieldsContainer {
    background: var(--background-light);
    width: 100%;
    border-radius: 5px;
    padding: 30px;
}

.targetImageDataContainer > aside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.targetImageDataContainer > aside > img {
    width: 20%;
}

.imageTargetText {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    color: var(--grey-dark);
    line-height: 30px;
    margin: 0 15px;
    margin-top: 50px;
}

.imageTargetText strong {
    color: var(--white);
}

.imageTargetText > p:last-child {
    margin-top: 30px;
}

.targetImageStepButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.targetImageStepButtonsContainerNext {
    display: flex;   
    align-items: center;
    margin-top: 30px;
    width: 100%;
    justify-content: flex-end;
}

.targetImageStepButtonsContainer > button,
.targetImageStepButtonsContainerNext > button {
    border-radius: 20px;
    padding: 0 25px;
    margin-bottom: 40px;
}



.filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 100px; */
    margin-top: 60px;
}

.filtersImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50%;
    height: 100%; 
}

.filtersImageContainer.withVideos {
    /* flex-direction: column-reverse; */
    justify-content: space-between;
}

.filtersImageContainer.withVideos > img {
    margin-top: 30px;
}

.filtersImageContainer > img {
    width: 60%;
    margin: 0 auto;
}   

.filtersDataContainer {
     width: 50%;
     height: 100%;
     position: relative;
     text-align: center;
}


.filtersDataContainer > h4 {
    color: var(--grey-light);
    line-height: 30px;
    font-family: 'Lalezar', cursive;
    font-size: 1.3rem;
    margin-bottom: 30px;
}


.filtersDataContainer > p {
    text-align: center;
    margin: 15px 0;
}

.filterListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.filterListContainer > p {
    text-align: center;
    font-size: 1rem;
    margin: 20px 0;
    color: var(--grey-dark);
}

.publishContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    z-index: 1000;
    /* padding-bottom: 100px; */
}

.publishContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    background: red;
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}


.publishContainer > img {
    width: 50%;
}

.publishContainer > main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* height: 400px; */
}

.publishContainer > main > h4, 
.publishContainer > main > h4 > strong {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    font-size: 2.2rem;
    margin-bottom: 20px;
    text-align: center;
}


.publishContainer > main > p:last-of-type {
    font-weight: normal;
    font-size: 0.9rem;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.publishContainer > main > h4 > strong {
    color: var(--primary-color);
}

.publishContainer > main > button {
    width: 200px;
    height:200px;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: var(--background-light);
}

.publishContainer > main > button > svg {
    margin-left: 5px;
}

.publishContainer > main > button > h4 {
    margin-bottom: 0;
    margin-top: 30px;
    
}

.publishContainer > main > button:hover {
    background: var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.boBackButton {
    margin-top: 40px !important;
    height: 60px !important;
}

.publishContainer > main  > p {
    margin-bottom: 30px;
    font-size: 1.2rem;
    color: var(--grey-dark);
}

.successContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 70px;
    z-index: 1000;
    padding-bottom: 100px;
}

.successContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    background: red;
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}

.successContainer > img {
    width: 40%;
    padding-right: 40px;
}

.successContainer > aside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
}

.successContainer > aside > h1 {
    text-align: center;
    font-family: 'Lalezar', cursive;
    font-weight: 300;
    font-size: 3rem;
    margin-bottom: 0px;
}

.successContainer > aside > h2,
.successContainer > aside > h3 {
    text-align: center;
    font-family: 'Montserrat', cursive;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 30px;
}

.successContainer > aside > h2 {
    font-family: 'Lalezar', cursive;
    font-size: 1.6rem;
    margin-bottom: 30px;
}

.successContainer > aside > h1 > strong {
    color: var(--primary-color);
}

.successContainer > aside > a > img {
    margin: 20px 0;
    width: 180px;
    transition: 0.2s;
}

.successContainer > aside > a > img:hover {
    transform: scale(1.1);
}

.successContainer > aside > a > h3 {
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 80px;
    margin-top: -30px;
}

.successButtonsContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.successButtonsContainer > button, .homeButton {
    width: 130px;
    height: 40px;
    border-radius: 20px;
}

.successContainer > aside > p {
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
}

.successContainer > aside > p > strong {
    color: var(--primary-color);
}

a {
    color: var(--white) !important;
}

.uploadingHeader {
    text-align: center;
}

.uploadingSpinner {
     width: 100%;
     height: 200px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.publishButtonsContainer {
    max-width: 100%;
    border-radius: 5px;
    background-color: var(--background-light);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px;
    flex-wrap: wrap;
}

.pubishOption {
    width: 120px;
    height: 120px;
    background-color: var(--background);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    flex-direction: column;
    text-align: center;
    margin: 10px;
    font-weight: normal;
    position: relative;
}

.pubishOption.disabled > svg > path,
.pubishButton.disabled > svg > path  {
    fill: white;
}

.premiumIcon > path  {
    fill: rgb(187, 169, 52) !important;

}

.pubishButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--background);
    font-weight: normal;
    border: 1px solid var(--primary-color);
}

.pubishOption:hover,
.pubishButton:hover {
    background: var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.disabled:hover {
    background: var(--primary-color);
    box-shadow: 0px 0px 12px 1px transparent;
    cursor: not-allowed;
}

.pubishOption:hover > svg > path,
.pubishOption:hover > .pubishButton > svg > path {
    fill: var(--white);
}

.pubishOption:hover > .premiumIcon > path  {
    fill: rgb(187, 169, 52);;
}


.pubishOption > p, .pubishButton > p {
    margin-top: 10px;
    font-size: .75rem;
    margin: 10px;
    margin-bottom: 0;
}

.maximumOfExperiences {
    margin: 45px 0px !important;
    text-align: center !important;
    font-weight: bold;
    font-size: 1.1rem;
    color: #F0386B !important;
}

.maximumOfExperiencesMail {
    color: var(--primary-color) !important;
}


.publishSpinner {
    color: var(--grey-dark);
}

.errorLoading {
    font-size: .8rem !important;
    margin-top: 15px;
}
.errorLoading > a {
    color: var(--primary-color) !important;
    font-weight: bold !important;
    margin-left: 5px;
}

.createExperienceSuccessContainer {
    margin-top: -100px;
}

@media (max-width: 945px) {
    .logoAndDatesContainer > img {
      display: none;
      width: 100%;
    }

    .logoAndDatesContainer main, 
    .targetImageDataContainer,
    .filtersContainer,
    .logoAndDatesContainer, 
    .targetImageContainer,
    .filtersDataContainer {
        width: 100%;
        flex-direction: column-reverse;
        margin-top: 50px;
        margin-bottom: 0;
    }

    .createExperienceContainer {
        justify-content: center;
    }

    .filtersDataContainer {
        margin-top: 30px;
    }

    .logoAndDatesContainer main {
        flex-direction: column;
    }

    .successContainer > aside {
        width: 100%;
    }


    .targetImageInfo, .filtersImageContainer {
        /* display: none; */
        width: 100%;
        max-width: 100%;
    }

    .previewContainer {
        right: 20px;
        bottom: 100px;
        left: auto;
    }

    .publishContainer > img,
    .successContainer > img {
        display: none;
    }

    .successContainer {
        margin-top: 20px;
    }


    .publishContainer > main > h4 {
        text-align: center;
    }
  }

  @media (max-width: 710px) {
    .successContainer > aside {
        width: 100%;
    }
  }

  @media (max-width: 530px) {
    .targetImageDataContainer,
    .filtersContainer,
    .publishContainer {
        margin-top: 0px;
    }
    .targetImageStepButtonsContainer {
        flex-wrap: wrap;

    }
    .targetImageStepButtonsContainer > button {
        font-size: .7rem;
        padding: 0 20px;
        margin-bottom: 20px;
    }
    .targetImageStepButtonsContainerNext > button {
        font-size: .7rem;
        padding: 0 20px;
        margin-bottom: 20px;
    }
  }

  @media (max-width: 420px) {
    .filterNameInputs button {
        padding: 0 20px;
        font-size: .7rem;
    }
    
    .previewContainer {
        right: 20px;
        bottom: 100px;
        left: auto;
        width: 90%;
        height: 250px;
    }

    .uploading > p {
        display: none;
    }

    .experienceNameContainer > p {
        font-size: .8rem;
        text-align: center;
    }

    .inputExperienceName {
        height: 50px;
        font-size: 1.5rem;
        
    }
    
    .inputExperienceName::-webkit-input-placeholder {
        font-size: 1.5rem;
    }
    
    .inputExperienceName:-ms-input-placeholder {
        font-size: 1.5rem;
    }
    
    .inputExperienceName::-ms-input-placeholder {
        font-size: 1.5rem;
    }
    
    .inputExperienceName::placeholder {
        font-size: 1.5rem;
    }
    .experienceNameInputContainer > svg {
        bottom: 13px;
    }

    .targetImageDataContainer > h4 {
        font-size: .9rem;
        margin-bottom: 40px;
        font-weight: bold;
        color: var(--grey-light);
        line-height: 30px;
    }
    
    
    .publishContainer > main > h4,
    .publishContainer > main > h4 > strong {
        font-size: 1.4rem;
    }
    

    .createExpNextButton {
        font-size: .7rem;
        padding: 0;
        width: auto;
        padding: 0 20px;
    }
    .successContainer {
        margin-top: 40px;
        padding-bottom: 0;
    }

    .successContainer > aside > h1 {
        font-size: 1.6rem;
    }

    .successContainer > aside > p {
        font-size: .8rem;
        line-height: 25px;
        margin-top: 35px;

    }
    .successButtonsContainer > button, .homeButton {
        width: auto;
        padding: 0 20px;
        font-size: .7rem;
    }

    .successButtonsContainer {
        margin-top: 40px;
    }

    .successContainer > aside > a > img {
        margin-top: 40px;
        height: 150px;
        width: 150px;
    }
    .successContainer > aside > a > h3 {
        margin-bottom: 0px;
    }

  }
  
.route {
    margin-top: 100px;
    padding-bottom: 0;
    position: relative;
    z-index: 10;
    min-height: calc(100vh - 160px);
}

.spinnerCircle {
    margin-top: 80px;
}

.experiencesContainer > header {
    padding-top: 129px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 30px;
}

.experiencesContainer h1 {
    font-family: 'Lalezar', cursive;
    margin-top: 0;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 0;

}


.experienceCardBody > aside > button {
    width: 100px;
    height: 42px;
    background-color: var(--primary-color);
    color: var(--white);
    border-radius: 5px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    transition: .2s;
    margin-left: 20px;
}


.experienceCardBody > aside > button:hover {
    background-color: var(--primary-color-hover);
}

.experiencesContainer p {
    color: var(--grey-dark);
}

.experiencesContainer p span {
    font-family: 'Orbitron', sans-serif;
    color: var(--white);
}

.listCreateButton {
    width: 120px;
    height: 40px;
    border-radius: 20px;
}

.loadingListOfExperiences {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.noExperiences {
    margin-top: 0px;
    padding-top: 100px;
    min-height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
}


.noExperiences::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 500px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 1;
    background: radial-gradient(
      51.74% 51.74% at 50% 50%,
      rgba(126, 65, 165, 0.19) 0%,
      rgba(126, 65, 165, 0) 100%
    );
  }

.noExperiences > img {
    max-width: 35vw;
    height: auto;
    padding-right: 80px;
}

.createFirstExperience {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
}

.createFirstExperience > h2 {
    font-family: 'Lalezar', sans-serif;
    font-size: 2.5rem;
    text-align: center;
}

.createFirstExperience > h2 > strong {
    color: var(--primary-color);
    font-weight: normal;
}

.createFirstExperience > button {
    width: 250px;
    height: 60px;
    border-radius: 30px;
    margin-top: 60px;
}


.experienceCardsContainer {
    padding-bottom: 100px;
    width: 100%;
    min-height: calc(100vh - 320px);
    margin: 0 auto;
}

.experienceCardsContainer > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.experienceCardsContainer > h2 {
    text-align: center;
    margin: 30px 0;
    font-size: 1.5rem;
}

.experienceListContainer {
    width: 60%;
    margin: 0 auto;
}

.experienceCardBody {
    background: var(--background-light);
    border-radius: 5px;
    margin-bottom: 10px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    transition: 0.2s;
}

.experienceCardBody:hover {
    transform: translateX(5px);
}

.experienceCardBody p {
    color: var(--white);
    width: 60%;
}

.experienceCardBody > aside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.experienceCardBody > aside > button {
    border-radius: 5px;
    width: 100px;
}

.noExperiences {
    width: 100%;
    justify-content: space-around;
    
}

.noExperiences > aside {
    text-align: center;
    position: relative;
    z-index: 100;
}

.noExperiences > aside > h1 {
    font-size: 2.2rem;
    margin-bottom: 50px;
}

.noExperiences > aside > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.noExperiences > aside > a > button {
    width: 240px;
    height: 50px;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 0.2s;
}

.noExperiences > aside > a > button:hover {
    background: var(--primary-color-hover);
}

@media (max-width: 940px) {
    .experienceCardsContainer {
        width: 100%;
    }

    .experienceCardBody > p {
        word-break: break-word;
        margin-right: 20px; 
    }

    .experienceCardBody > aside > button {
        padding: 0 20px;
        width: auto;
        margin-left: 8px;
    }

    .noExperiences > img {
        display: none;
    }
    
    .experienceListContainer {
        width: 100%;
        margin: 0;
    }

}

@media (max-width: 540px) {

    .experiencesContainer h1 {
        font-size: 1.5rem;
    }

    .experiencesContainer button {
        font-size: .8rem;
        width: auto;
        padding: 0 30px;
        
    }

    .experienceCardBody {
        padding: 20px;
    }

    .experiencesContainer p {
        font-size: .8rem;
    }

    .experienceCardsContainer > h2 {
        font-size: 1rem;
    }
    
    .createFirstExperience > h2 {
        font-size: 1.4rem;        
    }
    .createFirstExperience > button {
        font-size: 1rem;
    }
}
.headerContainer {
    max-width: 1044px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    /* background-color: var(--background); */
    z-index: 10000;
    position: fixed;
    top: 0;

}

.headerContainer aside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
}

p {
    padding: 0;
    margin: 0;
}   
.headerContainer > a > img {
  max-width: 350px;
}

.headerContainer aside p, .sideMenubuttonsContainer p {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.3rem;
    color: var(--grey-dark);
}

.sideMenubuttonsContainer p {
    margin-bottom: 30px;
}



.headerContainer button {
    width: 134px;
    height: 42px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem;
    margin-left: 20px;
    background-color: transparent;
    transition: 0.2s;
    padding: 0;
}

.headerLoginButton {
  background: var(--primary-color) !important;
}

.headerLoginButton:hover {
  background: var(--primary-color-hover) !important;
}


.logInWithGoogle {
    color: var(--google);
    border: solid 1px var(--google);
}

.logInWithFacebook {
    color: var(--facebook);
    border: solid 1px var(--facebook);
}

.logInWithEmail {
    color: var(--email);
    border: solid 1px var(--email);
}

.logInWithGoogle:hover {
    background: var(--google);
    color: var(--white)
}

.logInWithFacebook:hover {
    background: var(--facebook);
    color: var(--white)
}

.logInWithEmail:hover {
    background: var(--email);
    color: var(--white)
}

.logInWithGoogle:hover > svg path {
    fill: var(--white);
}

.logInWithFacebook:hover > svg path {
    fill: var(--white);
}

.logInWithEmail:hover > svg path {
    fill: var(--white);
}

.createButton {
    color: var(--white);
    background:var(--primary-color) !important;
    font-style: normal;
    font-weight: bold;
}


.contactUs {
  border: 1px solid var(--grey-light);
  color: var(--grey-light);
  font-weight: normal;
  cursor: pointer;
  transition: 0.2s;
}

.contactUs:hover {
  background: var(--grey-light);
  color: var(--background);
}

.createButton:hover {
    background: var(--primary-color-hover) !important;
}


.burgerContainer {
    position: fixed;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 100;
    display: none;
  }

  .burger {
    width: 30px;
    height: 3px;
    border-radius: 1.5px;
    background-color: var(--white);
    transition: all 0.5s ease-in-out;
  }

  .burger::before,
  .burger::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    border-radius: 1.5px;
    background-color: var(--white);
    transition: all 0.5s ease-in-out;
  }

  .burger::before {
    transform: translateY(-8px);
  }
  .burger::after {
    transform: translateY(8px);
  }

  /* BURGER ANIMATION */
  .burgerContainer.open .burger {
    transform: translateX(-50px);
    background-color: transparent;
    box-shadow: none;
  }

  .burgerContainer.open .burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .burgerContainer.open .burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  
  .sideMenu {
    display: block;
    position: relative;
    
    width: 0px;
    height: 0px;
    margin-top: -20px;
  }

  .sideMenuContainer {
    z-index: 5;
    width: 100vw;
    height: 105vh;
    position: fixed;
    transform: translate(calc(100vw), -70px);
    background-color: var(--background);
    transition: all 0.5s ease-in-out;
  }

  .sideMenubuttonsContainer a button,   .sideMenubuttonsContainer button  {
      margin-bottom: 15px;
      margin-top: 15px;
      margin-left: 0;
      width: 70vw;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      font-size: 2rem;
      padding: 0 30px;
  }

  .sideMenubuttonsContainer .logInWithGoogle > svg, .sideMenubuttonsContainer .logInWithFacebook > svg {
      width: 30px;
      height: 30px;      
  }

  .sideMenu.open .sideMenuContainer {
    transform: translate(calc(-100vw - 40px), -70px);
  }
  .sideMenuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sideMenuheaderLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    /* transform: scale(1.5); */
    /* margin-left: -10px; */
  }

  .sideMenulogoText {
    margin-left: 20px;
    color: var(--white);
    color: var(--white);
    margin-left: 16px;
    padding-bottom: 8px;
}

  .sideMenubuttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-direction: column;
  }



@media screen and (max-width: 1044px) {
    .headerContainer aside {
      right: 80px;
  }
}



@media screen and (max-width: 940px) {
    .headerContainer aside {
        display: none;
    }
    .burgerContainer {
        display: flex;
    }

    .headerContainer aside p {
        display: none;
    }

    .helloUser {
        display: none;
    }
}


@media screen and (max-width: 480px) {
    .sideMenu.open .sideMenuContainer {
        transform: translate(calc(-100vw - 4%), -70px);
      }

    .sideMenubuttonsContainer a button,   .sideMenubuttonsContainer button  {
        width: 60vw;
        height: 60px;
        font-size: 1.5rem;
    }
    
    .burgerContainer {
        right: 15px;
    }
    .headerContainer > a > img {
      max-width: 200px !important;
    }

}

.lpContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 250px;
    width: 100%;
    height: calc(100vh - 500px);
    margin-bottom: 40px;
}

.lpContainer > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lpContainer > ul > li{
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 3rem;
}

a {
  display: flex;
    justify-content: center;
    align-items: center;
}

button {
  height: 60px;
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 30px;
  font-size: 25px;
  padding: 0 60px;
  /* margin-top: 60px; */
  transition: 0.2s;
  z-index: 2;
  font-family: "Lalezar", cursive;
  font-weight: normal;
}

button:hover {
  background-color: var(--primary-color-hover);
  cursor: pointer;
}

@media screen and (max-width: 970px) {
    .lpContainer {
      flex-direction: column;
      margin-top: 200px;
      height: 100%;
    }
}

@media screen and (max-width: 710px) {
  .lpContainer {
    margin-top: 180px;
  }
  .lpContainer > ul {
    margin-left: -10px;
  }

  .lpContainer > ul > li{
    font-size: .9rem;
    line-height: 2rem;
  }
}

.footerContainer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2000;


    
    position: relative;
    bottom: 10px;
    
}

.footerContainer svg {
    margin: 0 12px;
}

.footerContainer p {
    font-family: 'Montserrat', sans-serif;
    color: var(--grey-dark);
}

@media screen and (max-width: 420px) {
    .footerContainer {
        position: relative;
        bottom: 10px;
    }
    .footerContainer p {
        font-size: 0.7rem;
    }
    
}
